import { IntlShape } from "react-intl";

import { Stand } from "@models/stand";
import { getConfig } from "@di";
import { UNKNOWN_GROUP, UNKNOWN_GROUP_MESSAGE } from "@constants";

export const getAliasedStandLabel = ({ alias, label }: Stand) => {
  const str = alias ? `${label}/${alias}` : label;

  return str.toUpperCase();
};

export const generateLabelById = (id: string) => {
  return id.split("-").slice(1).join("-");
};

export const getStandLabelByStandIdFromConfig = (
  standId: string
): string | null => {
  const { standIdToStandLabelMap } = getConfig();
  return standIdToStandLabelMap[standId] || null;
};

export const getGroupLabel = (label: string, intl: IntlShape) => {
  if (!label || label === UNKNOWN_GROUP) {
    return intl.formatMessage(UNKNOWN_GROUP_MESSAGE);
  }

  return label;
};

export const getTerminalTitle = (terminal: string, intl: IntlShape) => {
  if (!terminal || terminal === UNKNOWN_GROUP) {
    return intl.formatMessage(UNKNOWN_GROUP_MESSAGE);
  }

  return terminal;
};
