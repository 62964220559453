import { Locale, Locales } from "./types";

export const LOCALES = {
  en_US: "en-US",
  en_GB: "en-GB",
  de_DE: "de-DE",
  ja_JP: "ja-JP",
} as const;

export const AVAILABLE_LOCALES: Locales = Object.values(LOCALES);

export const DEFAULT_LOCALE: Locale = LOCALES.en_GB;

export const DEFAULT_LOCALES: Locales = [DEFAULT_LOCALE];

export const SAVED_LANG_CODE_LOCAL_STORAGE_KEY = "langCode";

export const COMPILED_LOCALES_PATH = "./compiled-lang/";

export const IGNORED_RE =
  /\[(\s+)?(ignore|ignored|(ignore|no)(-|\s)(translat(e|ion)))(\s+)?\]/gi;
